.subtitle-container {
	margin-top: 20px;
}

.subtitle-header {
	margin-bottom: 5px;
}

.subtitle-icon {
	margin-right: 8px;
}
