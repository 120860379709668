.icon-frame {
	display: inline-block;
	margin: 0 2.5px;
}

.social-tooltip {
	color: var(--WHITE);
	background: var(--DARK-BLUE);
	position: absolute;
	width: 204px;
	margin-top: 1.1%;
	padding: 8px;
	border-radius: var(--BORDER-RADIUS-DEFAULT);
	z-index: 999;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	opacity: 0;
	visibility: hidden;
	transition:
		visibility var(--TRANSITION-EASE),
		opacity var(--TRANSITION-EASE);
}

.social-tooltip::after {
	content: "";
	position: absolute;
	border-style: solid;
	bottom: 99%;
	left: 8%;
	border-width: 8px;
	border-color: transparent transparent var(--DARK-BLUE) transparent;
}

.visible-tooltip {
	opacity: 1;
	visibility: visible;
}
