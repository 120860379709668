.submit-popup {
	background: var(--WHITE);
	position: absolute;
	top: 25%;
	left: 15%;
	right: 15%;
	padding: 5px 10px;
	box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.4);
}

.success-popup {
	border: 2px solid var(--GREEN);
}

.failure-popup {
	border: 2px solid var(--RED);
}

.construction-popup {
	border: 2px solid var(--ORANGE);
}
