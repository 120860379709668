figure.post-image-container {
	position: relative;
	border: 1px solid var(--BLACK);
	border-radius: var(--BORDER-RADIUS-DEFAULT);
	width: 100%;
	padding-top: 75%;
	overflow: hidden;
}

img.clickable-post-image,
img.regular-post-image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
	object-fit: cover;
	transition: transform var(--TRANSITION-FAST);
}

img.clickable-post-image:hover {
	transform: scale(1.1);
}
