.skills-block {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	column-gap: 2.5%;
	row-gap: 10px;
	width: 90%;
	margin: 15px auto 0;
}

.skill-container {
	padding: 6px 15px;
	border: 1px solid var(--GREY);
	border-radius: var(--BORDER-RADIUS-DEFAULT);
}

.skill-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.skill-image {
	margin-right: 8px;
	width: 20px;
	height: 20px;
}

@media screen and (max-width: 1300px) {
	.skills-block {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}
