nav {
	/* SIZING */
	--NAV-WIDTH: 160px;

	/* POSITIONING */
	--NAV-LEFT: calc(50% - (var(--NAV-WIDTH) + 32%));
	--NAV-MIDDLE: calc(50% - (var(--NAV-WIDTH) / 2 + 25.5%));

	position: absolute;
	width: var(--NAV-WIDTH);
	top: var(--SPACING-TOP);
	z-index: 999;
}

.middle {
	left: var(--NAV-MIDDLE);
	transition: left var(--TRANSITION-DEFAULT);
}

.left {
	left: var(--NAV-LEFT);
	transition: left var(--TRANSITION-CARD);
}

.navbar-logo-position {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 30px auto;
}

.menu-icon {
	display: none;
	cursor: pointer;
}

@media screen and (max-width: 960px) {
	nav {
		background: var(--DARK-GREY);
		position: fixed;
		width: 100%;
		top: 0;
		transition: none;
	}

	.middle,
	.left {
		left: 0;
	}

	.navbar-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: var(--NAV-SMALL-HEIGHT);
	}

	.navbar-logo-position {
		margin: 0;
		margin-left: var(--SPACING-SMALL-LEFT);
	}

	.menu-icon {
		display: flex;
		margin-right: var(--SPACING-SMALL-LEFT);
	}

	.nav-menu {
		position: absolute;
		top: var(--NAV-SMALL-HEIGHT);
		left: 100%;
		width: 100%;
		border: 3px solid var(--DARK-GREY);
		border-top: none;
		transition: left var(--TRANSITION-EASE);
	}

	.nav-menu.active {
		left: 0;
	}
}
