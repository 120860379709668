.footer-container {
	padding-top: 30px;
}

p.footer-quote {
	margin-top: 5px;
}

span.footer-author {
	font-style: italic;
}
