.generic-icon {
	color: var(--BLACK);
	vertical-align: -10%;
	font-size: 32px;
	width: 32px;
	height: 32px;
}

.large {
	font-size: 48px;
	width: 48px;
	height: 48px;
}

.small {
	font-size: 18px;
	width: 18px;
	height: 18px;
}

.white-color {
	color: var(--WHITE);
}

.blue-color {
	color: var(--BLUE);
}

.green-color {
	color: var(--GREEN);
}

.red-color {
	color: var(--RED);
}

.orange-color {
	color: var(--ORANGE);
}

.clickable-blue,
i.clickable-grey {
	cursor: pointer;
	transition: color var(--TRANSITION-EASE);
}

.clickable-blue:hover {
	color: var(--DARK-BLUE);
}

.clickable-grey:hover {
	color: var(--GREY);
}
