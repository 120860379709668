.contact-form-container {
	width: 90%;
	margin: 15px auto 0;
}

form .form-control {
	width: 100%;
	margin: 6px 0 16px;
	padding: 5px 8px;
	border: 1px solid var(--BLACK);
	border-radius: var(--BORDER-RADIUS-DEFAULT);
	outline: none;
}

form .input-element {
	height: 30px;
}

form .textarea-element {
	resize: vertical;
	height: 100px;
}

form .form-control:hover {
	box-shadow: 0 0 6px 2px var(--DARK-BLUE);
}

form .form-control:focus {
	box-shadow: 0 0 6px 2px var(--BLUE);
}

form .form-submit-sizing {
	width: 70%;
	height: 45px;
	margin: 10px 15% 0;
}
