.home-center,
.home-left {
	z-index: 10;
}

.home-center {
	left: var(--CARD-MIDDLE);
	transition: left var(--TRANSITION-DEFAULT);
}

.home-left {
	left: var(--CARD-LEFT);
	transition: left var(--TRANSITION-CARD);
}

.headshot1 {
	background: url("../../images/home/headshot1.webp");
	transition: background var(--TRANSITION-DEFAULT);
}

.headshot2 {
	background: url("../../images/home/headshot2.webp");
	transition: background var(--TRANSITION-CARD);
}

.home-img-round {
	height: 175px;
	width: 175px;
	margin: auto;
	background-position-x: center;
	background-position-y: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: var(--BORDER-RADIUS-ROUND);
	border: 4px solid var(--DARK-BLUE);
}

.home-intro-name {
	margin: 15px 0;
}

.home-intro-bar {
	margin: 25px 0;
}

.social-media {
	margin: 30px 0;
}

@media screen and (max-width: 960px) {
	.home-center {
		top: var(--SPACING-SMALL-TOP);
		left: var(--SPACING-SMALL-LEFT);
	}

	.home-left {
		display: none;
	}
}
