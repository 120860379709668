.logo-container,
.personal-logo {
	height: 95px;
	width: 95px;
	border-radius: var(--BORDER-RADIUS-ROUND);
}

.personal-logo {
	background: linear-gradient(
		45deg,
		var(--LOGO-OUT-BLUE) 20%,
		var(--LOGO-IN-BLUE) 50%,
		var(--LOGO-OUT-BLUE) 80%
	);
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid var(--BLACK);
	transition:
		color var(--TRANSITION-EASE),
		transform var(--TRANSITION-LONG);
}

.personal-logo:hover {
	color: var(--HOVER-GREY);
	transform: rotate(360deg);
}

@media screen and (max-width: 960px) {
	.logo-container,
	.personal-logo {
		height: 50px;
		width: 50px;
	}
}
